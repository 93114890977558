import {
    Checkbox,
    Skeleton,
    Table as ChakraTable,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { flexRender, ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from '@/hooks/useToast';

interface TableProps<TItem> {
    data?: TItem[];
    columns: ColumnDef<TItem, string>[];
    isLoading?: boolean;
    selected: string[];
    keyProperty: keyof TItem;
    onChange: (items: string[]) => void;
    maxSelected?: number;
}

const SelectionTable = <TItem,>({
    columns,
    data = [],
    isLoading = false,
    selected,
    keyProperty,
    onChange,
    maxSelected,
}: TableProps<TItem>) => {
    const { t } = useTranslation('common');
    const toast = useToast();

    const rowSelection = useMemo(
        () => selected.reduce((acc, id) => ({ ...acc, ...{ [id]: true } }), {}),
        [selected],
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        onStateChange: (updater) => {
            if (typeof updater === 'function') {
                const update = updater(table.getState());
                if (maxSelected && Object.keys(update.rowSelection).length > maxSelected) {
                    toast({
                        title: t('max_selected', { max: maxSelected }),
                        status: 'warning',
                    });
                    const selectedKeys = Object.keys(update.rowSelection).slice(0, maxSelected);
                    const newSelection = selectedKeys.reduce(
                        (acc, key) => ({ ...acc, [key]: true }),
                        {},
                    );
                    onChange(Object.keys(newSelection));
                } else {
                    onChange(Object.keys(update.rowSelection));
                }
            } else {
                onChange(Object.keys(updater.rowSelection));
            }
        },
        getRowId(row, relativeIndex, parent) {
            return parent ? [parent.id, row[keyProperty]].join('.') : (row[keyProperty] as string);
        },
        state: {
            rowSelection,
        },
    });

    return (
        <>
            <TableContainer>
                <ChakraTable>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                <Th>
                                    <Checkbox
                                        isChecked={table.getIsAllRowsSelected()}
                                        onChange={table.getToggleAllRowsSelectedHandler()}
                                    />
                                </Th>
                                {headerGroup.headers.map((header) => (
                                    <Th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext(),
                                              )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {isLoading
                            ? [...new Array(5)].map((i) => (
                                  <Tr key={`loader-${i}`}>
                                      {columns.map((_, i) => (
                                          <Td key={i}>
                                              <Skeleton
                                                  startColor="gray.400"
                                                  endColor="gray.200"
                                                  height={4}
                                                  width={100}
                                              />
                                          </Td>
                                      ))}
                                  </Tr>
                              ))
                            : table.getRowModel().rows.map((row) => (
                                  <Tr key={row.id}>
                                      <Td>
                                          <Checkbox
                                              isChecked={row.getIsSelected()}
                                              onChange={row.getToggleSelectedHandler()}
                                          />
                                      </Td>
                                      {row.getVisibleCells().map((cell) => (
                                          <Td key={cell.id}>
                                              {flexRender(
                                                  cell.column.columnDef.cell,
                                                  cell.getContext(),
                                              )}
                                          </Td>
                                      ))}
                                  </Tr>
                              ))}
                        {!isLoading && table.getRowModel().rows.length === 0 && (
                            <Tr>
                                <Td
                                    color="gray.500"
                                    textAlign="center"
                                    colSpan={table.getVisibleFlatColumns().length}
                                >
                                    {t('no_data')}
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </ChakraTable>
            </TableContainer>
        </>
    );
};

export default SelectionTable;
