import {
    Skeleton,
    Table as ChakraTable,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { flexRender, ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

interface TableProps<TItem> {
    data?: TItem[];
    columns: ColumnDef<TItem, string>[];
    isLoading?: boolean;
}

const Table = <TItem,>({ columns, data = [], isLoading = false }: TableProps<TItem>) => {
    const { t } = useTranslation('common');
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    if (!data) {
        return null;
    }

    return (
        <TableContainer>
            <ChakraTable>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <Th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {isLoading
                        ? [...new Array(5)].map((i) => (
                              <Tr key={`loader-${i}`}>
                                  {columns.map((_, i) => (
                                      <Td key={i}>
                                          <Skeleton
                                              startColor="gray.400"
                                              endColor="gray.200"
                                              height={4}
                                              width={100}
                                          />
                                      </Td>
                                  ))}
                              </Tr>
                          ))
                        : table.getRowModel().rows.map((row) => (
                              <Tr key={row.id}>
                                  {row.getVisibleCells().map((cell) => (
                                      <Td key={cell.id}>
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                          )}
                                      </Td>
                                  ))}
                              </Tr>
                          ))}
                    {!isLoading && table.getRowModel().rows.length === 0 && (
                        <Tr>
                            <Td
                                color="gray.500"
                                textAlign="center"
                                colSpan={table.getVisibleFlatColumns().length}
                            >
                                {t('no_data')}
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </ChakraTable>
        </TableContainer>
    );
};

export default Table;
