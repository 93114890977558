import { StoreInvoiceStatus } from '@/generated/typing';
import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { HiBarsArrowDown } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'next/router';

export const ListStatusFilter = () => {
    const { t } = useTranslation(['storeInvoices', 'common']);
    const router = useRouter();

    const getOptionsFromStoreInvoicesStatus = (): {
        label: string;
        value: StoreInvoiceStatus;
    }[] => {
        return Object.values(StoreInvoiceStatus).map((value: StoreInvoiceStatus) => {
            return {
                label: t(`storeInvoices:status.${value}`),
                value: value,
            };
        });
    };

    return (
        <Menu>
            <Button as={MenuButton} leftIcon={<Icon as={HiBarsArrowDown} />}>
                {t('common:filter_by_status')}
            </Button>
            <Portal>
                <MenuList>
                    {getOptionsFromStoreInvoicesStatus().map((option) => (
                        <MenuItem
                            key={option.value}
                            onClick={() => {
                                router.replace(
                                    {
                                        query: {
                                            ...router.query,
                                            status: option.value,
                                        },
                                    },
                                    undefined,
                                    { scroll: false },
                                );
                            }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </MenuList>
            </Portal>
        </Menu>
    );
};
