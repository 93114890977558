import { Button, Icon } from '@chakra-ui/react';
import { useTranslation } from '@/hooks/useTranslation';
import { useRouter } from 'next/router';
import { useState } from 'react';
import DateFilterModal from '../table/DateFilterModal';
import { HiMiniCalendarDays } from 'react-icons/hi2';

export const ListDateFilter = () => {
    const router = useRouter();
    const { t } = useTranslation(['common']);
    const [dateFilterIsOpen, setDateFilterIsOpen] = useState(false);

    return (
        <>
            <Button
                onClick={() => setDateFilterIsOpen(true)}
                leftIcon={<Icon as={HiMiniCalendarDays} />}
            >
                {t('common:filter_by_date')}
            </Button>
            <DateFilterModal
                isOpen={dateFilterIsOpen}
                onSubmit={(from, to) => {
                    setDateFilterIsOpen(false);
                    router.replace({
                        query: {
                            ...router.query,
                            from: from.toISOString(),
                            to: to.toISOString(),
                        },
                    });
                }}
                onClose={() => setDateFilterIsOpen(false)}
                from={router.query.from ? new Date(router.query.from as string) : new Date()}
                to={router.query.to ? new Date(router.query.to as string) : new Date()}
            />
        </>
    );
};
