import { Center, Flex, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from '@/hooks/useTranslation';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useListContext } from './list.context';
import { IconButtonV2 } from '@/components/ui/IconButtonV2';

export const ListPagination = () => {
    const router = useRouter();
    const { t } = useTranslation(['common']);
    const { currentPage, pageMeta, pageLabel } = useListContext();

    const pagesToDisplay = useMemo(() => {
        return [
            ...new Set([
                1,
                currentPage - 2,
                currentPage - 1,
                currentPage,
                currentPage + 1,
                currentPage + 2,
                ...(pageMeta ? [pageMeta.pageCount] : []),
            ]),
        ].filter((page) => page > 0 && page <= (pageMeta ? pageMeta.pageCount : 1));
    }, [currentPage, pageMeta]);

    const handleChange = (page: number) => {
        router.replace(
            {
                query: { ...router.query, [pageLabel]: page },
            },
            undefined,
            { scroll: false },
        );
    };

    if (!pageMeta) {
        return null;
    }

    return (
        <Flex justifyContent="space-between" alignItems="center" gap={2} mt={1}>
            {!!pageMeta.totalCount && (
                <Text color="text3" fontSize="sm" fontWeight="medium">
                    {t('common:table_result_count', { count: pageMeta.totalCount })}
                </Text>
            )}
            {!!pagesToDisplay.length && (
                <HStack justifyContent="flex-end">
                    <IconButtonV2
                        disabled={!pageMeta.hasPreviousPage}
                        variant="ghost"
                        label={t('common:previous')}
                        IconComponent={HiChevronLeft}
                        onClick={() => {
                            if (pageMeta.hasPreviousPage) {
                                handleChange(currentPage - 1);
                            }
                        }}
                    />

                    {pagesToDisplay.map((page: number) => {
                        return (
                            <Center
                                key={page}
                                transition="all 0.2s"
                                onClick={() => {
                                    handleChange(page);
                                }}
                                cursor="pointer"
                                fontSize="sm"
                                fontWeight={currentPage === page ? 'bold' : 'normal'}
                                borderRadius="xl"
                                color={currentPage === page ? 'primary.text' : 'gray.600'}
                                backgroundColor={
                                    currentPage === page ? 'primary.50' : 'transparent'
                                }
                                height="2rem"
                                width="2rem"
                            >
                                {page}
                            </Center>
                        );
                    })}
                    <IconButtonV2
                        disabled={!pageMeta.hasNextPage}
                        variant="ghost"
                        label={t('common:next')}
                        IconComponent={HiChevronRight}
                        onClick={() => {
                            if (pageMeta.hasNextPage) {
                                handleChange(currentPage + 1);
                            }
                        }}
                    />
                </HStack>
            )}
        </Flex>
    );
};
